







































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseActionButtonWidget from '@/components/action-buttons/VueBaseActionButtonWidget';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { Getter, namespace } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import ToastActionParams from '@/utils/types/ToastActionParams';
import MessageMenuItem from '@/utils/enums/chat/MessageMenuItem';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';

const communityUserStore = namespace('CommunityUserStore');
const toastStore = namespace('ToastStore');

@Component({
  components: {
    ButtonComponent,
    FontAwesomeComponent,
  },
})
export default class ActionButtonBlock extends VueBaseActionButtonWidget {
  @Prop({ required: true })
  protected readonly actionResult!: CommunityUser;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @communityUserStore.Getter
  private isLoading!: boolean;

  @communityUserStore.Getter
  private fetchBlockedCommunityUserUids!: string[];

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  private isBlocked = false;

  private icon = 'far fa-ban';

  private get text(): string {
    return `${this.isBlocked ? this.$t('actions.unblock') : this.$t('actions.block')}`;
  }

  private get variant(): string {
    return this.isBlocked ? 'alt-2' : 'secondary';
  }

  private get fullName(): string {
    return CommunityUser.hydrate(this.actionResult).fullName;
  }

  private get isDisplayed(): boolean {
    return (this.actionType === this.ActionButtonType.BLOCK
        && this.featureByKey(FeatureKeys.COMMUNITY_ENABLE_BLOCKED_USERS)
        && this.featureByKey(FeatureKeys.COMMUNITY_ENABLE_BLOCKED_USERS).enabled
        && this.entityType === this.EntityTypeEnum.USER
    );
  }

  created(): void {
    if (this.authUser) {
      this.isBlocked = this.authUser.haveBlockedUser(this.entityUid);
    }
    this.buttonsDisplay();
  }

  @Watch('reRender')
  private buttonsDisplay(): void {
    this.commit('updateButtons', {
      index: this.index,
      visible: this.isDisplayed,
    });
  }

  @Watch('fetchBlockedCommunityUserUids', { deep: true })
  private setIsBlocked(): void {
    this.isBlocked = this.fetchBlockedCommunityUserUids
      .filter((userUid: string) => userUid === this.entityUid).length > 0;
    this.commit('reRenderButtons');
  }

  @Watch('actionResult')
  private syncActionResultData(): void {
    this.isBlocked = !!this.actionResult.isBlocked;
  }

  private toggleBlockModal(): void {
    if (this.authUser) {
      this.$eventsBus.emit('open-conversation-option-modal', {
        users: [{
          uid: this.entityUid,
          fullName: this.fullName,
        }],
        groupId: this.entityUid,
        actionType: this.isBlocked ? MessageMenuItem.UNBLOCK : MessageMenuItem.BLOCK,
      });
    } else {
      this.$bvModal.show('sign-in-action-modal');
    }
  }
}
